const EnvUtils = {
  getStringValue: (value: string | undefined, defaultValue: string) : string => {
    return value || defaultValue;
  },

  getBoolValue: (value: string | undefined, defaultValue: boolean) : boolean => {
    if (value === undefined) {
      return defaultValue;
    }
    return value.trim().toUpperCase() === 'TRUE';
  },
};

export default EnvUtils;
