/* eslint-disable @typescript-eslint/no-var-requires */
import "@/src/ui/styles/style.scss";
import "slick-carousel/slick/slick.css";
import "@/src/ui/styles/slick-theme.scss";

import type { AppProps } from "next/app";
import React from "react";
import { appWithTranslation } from "next-i18next";
import Head from "next/head";

// Conditionally inject axe into the page.
// This only happens outside of production and in a browser (not SSR).
if (typeof window !== "undefined" && process.env.NODE_ENV !== "production") {
  // const ReactDOM = require("react-dom");
  // const axe = require("@axe-core/react");
  // axe(React, ReactDOM, 1000);
}

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <title>hoteleselba.front</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=2" />
        {/*{process.env.NODE_ENV === "production" && (*/}
        {/*  <meta httpEquiv="Content-Security-Policy" content="default-src 'self'; child-src 'none'; style-src 'unsafe-inline'; object-src 'none'" />*/}
        {/*)}*/}
        <meta httpEquiv="referrer" content="no-referrer, strict-origin-when-cross-origin" />
        <script src={`https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY}`} async defer></script>
        <script src='https://api.mapbox.com/mapbox-gl-js/v1.0.0/mapbox-gl.js'></script>
        <link href='https://api.mapbox.com/mapbox-gl-js/v1.0.0/mapbox-gl.css' rel='stylesheet' />
        <script src='/libraries/leaflet/leaflet.js'></script>
        <link href='/libraries/leaflet/leaflet.css' rel='stylesheet' />
        <script src='/libraries/leaflet-gridLayer-googleMutant/Leaflet.GoogleMutant.js'></script>
      </Head>
      <Component {...pageProps} />
    </>
  );
}

export default appWithTranslation(MyApp);
